#Loading {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: 300ms;
}

#Loading.hide {
  opacity: 0;
  visibility: hidden;
}

#Loading .loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

#Loading .loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #232323;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------------------

Table of contents
        
    * Basic
    * Forms
    * Buttons
    * Utilities
    * Components
    
---------------------------------- */

/*============================== 
  Basic
================================*/



/*============================== 
  Forms
================================*/
/* input,
textarea,
select {
  padding: 12px 15px;
}
input.form-check-input {
  width: auto;
}
.custom-control-label.border-danger::before {
  border-color: #dc3545 !important;
} */
/* main-select ---------------- */
/* .main-select__control {
  border-color: #d1d1d1;
  border-radius: 0 !important;
  font-size: 14px;
  height: 46px;
}
.main-select__control--is-focused {
  border-color: #585858 !important;
  box-shadow: none !important;
}
.main-select__menu {
  border: 1px solid #585858;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  top: 45px !important;
}
.main-select__menu-list {
  padding: 0 !important;
}
.main-select__option {
  border-bottom: 1px solid #ddd;
}
.main-select__option--is-focused,
.main-select__option--is-selected {
  background-color: #fff2d6 !important;
  color: inherit !important;
}
.main-select__option:active {
  background-color: inherit !important;
  color: inherit !important;
}
.main-select.border-danger .main-select__control,
.main-select.border-danger .main-select__control--is-focused {
  border-color: #dc3545 !important;
}
.main-select.border-danger .main-select__placeholder {
  color: #dc3545;
} */

/*============================== 
  Buttons
================================*/
/* .btn-block {
  width: 100%;
}
.btn-outline-secondary:focus {
  box-shadow: none;
} */

/* btn primary */
/* .btn.btn-primary {
  background: #e6a00a;
  border: 2px solid #e6a00a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #fff;
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background: #fff;
  color: #232323;
  border-color: #232323;
}
.btn.btn-primary:hover span,
.btn.btn-primary:active span {
  color: #232323;
}
.btn.btn-primary:focus {
  background: #fff;
  color: #fff;
  border-color: #232323;
}
.btn.btn-primary:focus span {
  color: #232323;
}
.btn.btn-primary span {
  color: #fff;
} */

/* btn outline primary */
/* .btn.btn-outline-primary {
  background: #fff;
  border: 2px solid #e6a00a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #e6a00a;
}
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active {
  background: #232323;
  color: #fff;
  border-color: #232323;
}
.btn.btn-outline-primary:hover span,
.btn.btn-outline-primary:active span {
  color: #fff;
}
.btn.btn-outline-primary:focus {
  background: #232323;
  color: #fff;
  border-color: #232323;
}
.btn.btn-outline-primary:focus span {
  color: #fff;
}
.btn.btn-outline-primary span {
  color: #232323;
} */

/*============================== 
  Utilities
================================*/
/* .line-height-normal,
.lh-normal {
  line-height: normal;
}
.fs-12 {
  font-size: 12px;
} */

/*============================== 
  Components
================================*/

/* Preloader ------------------ */


/* Navbar --------------------- */


/* Hero ========================*/
/* .section-home {
  padding-bottom: 0;
}
.hero-text {
  font-family: 'Montserrat', sans-serif;
}
.hero-h2 {
  color: #6f6f6f;
  line-height: 1.7;
  margin-bottom: 20px;
} */

/* About me section ============ */
/* .about-me-list {
  padding-left: 24px;
}
.about-me-list li {
  list-style-type: circle;
  padding-bottom: 12px;
} */

/* Testimonials section ======== */
.testimonials .avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}
.testimonials .slick-dots {
  margin-top: 30px;
}
.testimonials .slick-dots li {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  list-style: none;
}
.slick-dots button {
  color: #d5d5d5;
  background-color: #d5d5d5;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  height: 10px;
  overflow: hidden;
  width: 10px;
}
.slick-dots button:hover,
.slick-active button {
  color: #232323;
  background-color: #232323;
  border-color: #232323;
}

/* Services section ============ */
.services .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}
/* Page detail (program grupowy) */
.page-detail-pg .order-table .row:hover {
  background-color: #fff2d6;
}
/* Page order (program grupowy) = */
.page-order-pg .card {
  max-width: 350px;
}
.page-order-pg .card-title {
  font-size: 22px;
  line-height: normal;
  font-weight: bold;
}

/*===================================
  * Layout / Content
    * Reset
    * Basic
  * Components
    * Inputs
    * Dropdowns
    * Buttons
    * Modal
  * Utilities
    * Borders
    * Colors
    * Grid
    * Sizing
====================================*/

/*===================================
  Layout / Content
====================================*/

/* Reset -------------------------- */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html, #root {
  height: 100vh;
}
body, html {
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}
p {
  line-height: 1.7;
  word-spacing: 1px;
}
a:focus, a:active, button:focus, button:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none;
}
a, a:active, a:focus {
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}
a:hover {
  text-decoration: underline;
}
b, strong {
  font-weight: 500;
}

/* Basic -------------------------- */
body {
  color: #1b1e2d;
}
p {
  color: #1b1e2d;
}
a {
  color: #e07b0f;
}
a:hover {
  color: #c97010;
}

/*===================================
  Components
====================================*/

/* Inputs ------------------------- */
.form-group.floating {
  position: relative;
  text-align: left;
}
.floating .form-control {
  height: auto;
  padding-top: 1.5rem;
}
.floating label {
  color: rgba(27, 30, 46, .5);
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(1rem, -2.5rem);
  transition: all 0.2s ease-out;
}
.form-control:focus {
  border-color: rgba(27, 30, 46, .2);
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .15);
}
.floating .form-control:focus + label,
.floating .form-control:not(:placeholder-shown) + label {
  color: rgba(27, 30, 46, .6);
  font-size: .8rem;
  transform: translate(.8rem, -3rem);
}
.invalid .form-control:not(:focus) {
  background-color: #FFF2F4;
  border-color: #dc3545;
}
.invalid label {
  color: #dc3545 !important;
}
.custom-control-label::before,
.custom-control-label::after {
  top: .15rem
}

/* checkbox */
.custom-control-input:checked~.custom-control-label::before {
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(224, 123, 15, .7);
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: rgba(224, 123, 15, .8);
}
.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: rgba(224, 123, 15, .5);
  border-color: rgba(224, 123, 15, .5)
}
.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .15);
}

/* Dropdowns ---------------------- */
.dropdown-item.active, .dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

/* Buttons ------------------------ */
.btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.4rem 1rem
}

/* Primary */
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background-color: #1b1e2d;
  border-color: #1b1e2d;
}
.btn-primary:hover{
  background-color: rgba(27, 30, 46, .9);
  border-color: rgba(27, 30, 46, .9);
}
.btn-primary:focus{
  background-color: #1b1e2d;
  border-color: #1b1e2d;
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .3);
}
.btn-primary:disabled {
  cursor: not-allowed;
}
.btn-primary:disabled:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(27, 30, 46, .8);
  border-color: rgba(27, 30, 46, .8);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .3);
}

/* Primary outline */
.btn-outline-primary {
  border-color: #1b1e2d;
  color: #1b1e2d;
}
.btn-outline-primary:hover {
  background-color: #1b1e2d;
  border-color: #1b1e2d;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(27, 30, 46, .8);
  border-color: rgba(27, 30, 46, .8);
  color: #ffffff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .4);
}

/* Secondary */
.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:disabled:hover {
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.btn-secondary:hover {
  background-color: #c16400;
  border-color: #c16400;
}
.btn-secondary:focus {
  background-color: #e07b0f;
  border-color: #e07b0f;
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}
.btn-secondary:disabled {
  cursor: not-allowed;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: rgba(224, 123, 15, .8);
  border-color: rgba(224, 123, 15, .8);
  color: #ffffff;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}

/* Secondary outline */
.btn-outline-secondary {
  border-color: #e07b0f;
  color: #e07b0f;
}
.btn-outline-secondary:hover{
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: rgba(224, 123, 15, .8);
  border-color: rgba(224, 123, 15, .8);
  color: #ffffff;
}
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}

/* Button Link */
.btn-link {
  color: #e07b0f;
}
.btn-link:hover {
  color: #c16400;
}
.btn-link:focus {
  box-shadow: none;
}

/* Modal */


/*===================================
  Utilities
====================================*/

.xs-border-bottom {
  border-bottom: 1px solid rgb(222, 226, 230);
}

.font-weight-300 {
  font-weight: 300;
}

/* Borders ------------------------ */
@media (min-width: 768px) {
  .md-border-right {
    border-right: 1px solid rgb(222, 226, 230);
  }
  .md-border-bottom {
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  .md-no-border-bottom {
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .lg-border-right {
    border-right: 1px solid rgb(222, 226, 230);
  }
  .lg-no-border-right {
    border-right: none;
  }
  .lg-border-bottom {
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  .lg-no-border-bottom {
    border-bottom: none;
  }
}

/* Colors ------------------------ */
.bg-light-gray {
  background-color:#f7f7f7;
}

/* Grid */
.d-grid {
  display: grid;
}
.place-content-center {
  place-content: center;
}

/* Sizing ------------------------ */
@media (min-width: 768px) {
  .mw-md-500 {
    max-width: 500px;
  }
}

