/* ----------------------------------

Table of contents
        
    * Basic
    * Forms
    * Buttons
    * Utilities
    * Components
    
---------------------------------- */

/*============================== 
  Basic
================================*/



/*============================== 
  Forms
================================*/
/* input,
textarea,
select {
  padding: 12px 15px;
}
input.form-check-input {
  width: auto;
}
.custom-control-label.border-danger::before {
  border-color: #dc3545 !important;
} */
/* main-select ---------------- */
/* .main-select__control {
  border-color: #d1d1d1;
  border-radius: 0 !important;
  font-size: 14px;
  height: 46px;
}
.main-select__control--is-focused {
  border-color: #585858 !important;
  box-shadow: none !important;
}
.main-select__menu {
  border: 1px solid #585858;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  top: 45px !important;
}
.main-select__menu-list {
  padding: 0 !important;
}
.main-select__option {
  border-bottom: 1px solid #ddd;
}
.main-select__option--is-focused,
.main-select__option--is-selected {
  background-color: #fff2d6 !important;
  color: inherit !important;
}
.main-select__option:active {
  background-color: inherit !important;
  color: inherit !important;
}
.main-select.border-danger .main-select__control,
.main-select.border-danger .main-select__control--is-focused {
  border-color: #dc3545 !important;
}
.main-select.border-danger .main-select__placeholder {
  color: #dc3545;
} */

/*============================== 
  Buttons
================================*/
/* .btn-block {
  width: 100%;
}
.btn-outline-secondary:focus {
  box-shadow: none;
} */

/* btn primary */
/* .btn.btn-primary {
  background: #e6a00a;
  border: 2px solid #e6a00a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #fff;
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background: #fff;
  color: #232323;
  border-color: #232323;
}
.btn.btn-primary:hover span,
.btn.btn-primary:active span {
  color: #232323;
}
.btn.btn-primary:focus {
  background: #fff;
  color: #fff;
  border-color: #232323;
}
.btn.btn-primary:focus span {
  color: #232323;
}
.btn.btn-primary span {
  color: #fff;
} */

/* btn outline primary */
/* .btn.btn-outline-primary {
  background: #fff;
  border: 2px solid #e6a00a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #e6a00a;
}
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active {
  background: #232323;
  color: #fff;
  border-color: #232323;
}
.btn.btn-outline-primary:hover span,
.btn.btn-outline-primary:active span {
  color: #fff;
}
.btn.btn-outline-primary:focus {
  background: #232323;
  color: #fff;
  border-color: #232323;
}
.btn.btn-outline-primary:focus span {
  color: #fff;
}
.btn.btn-outline-primary span {
  color: #232323;
} */

/*============================== 
  Utilities
================================*/
/* .line-height-normal,
.lh-normal {
  line-height: normal;
}
.fs-12 {
  font-size: 12px;
} */

/*============================== 
  Components
================================*/

/* Preloader ------------------ */


/* Navbar --------------------- */


/* Hero ========================*/
/* .section-home {
  padding-bottom: 0;
}
.hero-text {
  font-family: 'Montserrat', sans-serif;
}
.hero-h2 {
  color: #6f6f6f;
  line-height: 1.7;
  margin-bottom: 20px;
} */

/* About me section ============ */
/* .about-me-list {
  padding-left: 24px;
}
.about-me-list li {
  list-style-type: circle;
  padding-bottom: 12px;
} */

/* Testimonials section ======== */
.testimonials .avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}
.testimonials .slick-dots {
  margin-top: 30px;
}
.testimonials .slick-dots li {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  list-style: none;
}
.slick-dots button {
  color: #d5d5d5;
  background-color: #d5d5d5;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  height: 10px;
  overflow: hidden;
  width: 10px;
}
.slick-dots button:hover,
.slick-active button {
  color: #232323;
  background-color: #232323;
  border-color: #232323;
}

/* Services section ============ */
.services .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}
/* Page detail (program grupowy) */
.page-detail-pg .order-table .row:hover {
  background-color: #fff2d6;
}
/* Page order (program grupowy) = */
.page-order-pg .card {
  max-width: 350px;
}
.page-order-pg .card-title {
  font-size: 22px;
  line-height: normal;
  font-weight: bold;
}
