/*===================================
  * Layout / Content
    * Reset
    * Basic
  * Components
    * Inputs
    * Dropdowns
    * Buttons
    * Modal
  * Utilities
    * Borders
    * Colors
    * Grid
    * Sizing
====================================*/

/*===================================
  Layout / Content
====================================*/

/* Reset -------------------------- */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html, #root {
  height: 100vh;
}
body, html {
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}
p {
  line-height: 1.7;
  word-spacing: 1px;
}
a:focus, a:active, button:focus, button:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none;
}
a, a:active, a:focus {
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}
a:hover {
  text-decoration: underline;
}
b, strong {
  font-weight: 500;
}

/* Basic -------------------------- */
body {
  color: #1b1e2d;
}
p {
  color: #1b1e2d;
}
a {
  color: #e07b0f;
}
a:hover {
  color: #c97010;
}

/*===================================
  Components
====================================*/

/* Inputs ------------------------- */
.form-group.floating {
  position: relative;
  text-align: left;
}
.floating .form-control {
  height: auto;
  padding-top: 1.5rem;
}
.floating label {
  color: rgba(27, 30, 46, .5);
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(1rem, -2.5rem);
  transition: all 0.2s ease-out;
}
.form-control:focus {
  border-color: rgba(27, 30, 46, .2);
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .15);
}
.floating .form-control:focus + label,
.floating .form-control:not(:placeholder-shown) + label {
  color: rgba(27, 30, 46, .6);
  font-size: .8rem;
  transform: translate(.8rem, -3rem);
}
.invalid .form-control:not(:focus) {
  background-color: #FFF2F4;
  border-color: #dc3545;
}
.invalid label {
  color: #dc3545 !important;
}
.custom-control-label::before,
.custom-control-label::after {
  top: .15rem
}

/* checkbox */
.custom-control-input:checked~.custom-control-label::before {
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(224, 123, 15, .7);
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: rgba(224, 123, 15, .8);
}
.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: rgba(224, 123, 15, .5);
  border-color: rgba(224, 123, 15, .5)
}
.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .15);
}

/* Dropdowns ---------------------- */
.dropdown-item.active, .dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

/* Buttons ------------------------ */
.btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.4rem 1rem
}

/* Primary */
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background-color: #1b1e2d;
  border-color: #1b1e2d;
}
.btn-primary:hover{
  background-color: rgba(27, 30, 46, .9);
  border-color: rgba(27, 30, 46, .9);
}
.btn-primary:focus{
  background-color: #1b1e2d;
  border-color: #1b1e2d;
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .3);
}
.btn-primary:disabled {
  cursor: not-allowed;
}
.btn-primary:disabled:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(27, 30, 46, .8);
  border-color: rgba(27, 30, 46, .8);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .3);
}

/* Primary outline */
.btn-outline-primary {
  border-color: #1b1e2d;
  color: #1b1e2d;
}
.btn-outline-primary:hover {
  background-color: #1b1e2d;
  border-color: #1b1e2d;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(27, 30, 46, .8);
  border-color: rgba(27, 30, 46, .8);
  color: #ffffff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 30, 46, .4);
}

/* Secondary */
.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:disabled:hover {
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.btn-secondary:hover {
  background-color: #c16400;
  border-color: #c16400;
}
.btn-secondary:focus {
  background-color: #e07b0f;
  border-color: #e07b0f;
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}
.btn-secondary:disabled {
  cursor: not-allowed;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: rgba(224, 123, 15, .8);
  border-color: rgba(224, 123, 15, .8);
  color: #ffffff;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}

/* Secondary outline */
.btn-outline-secondary {
  border-color: #e07b0f;
  color: #e07b0f;
}
.btn-outline-secondary:hover{
  background-color: #e07b0f;
  border-color: #e07b0f;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: rgba(224, 123, 15, .8);
  border-color: rgba(224, 123, 15, .8);
  color: #ffffff;
}
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 123, 15, .4);
}

/* Button Link */
.btn-link {
  color: #e07b0f;
}
.btn-link:hover {
  color: #c16400;
}
.btn-link:focus {
  box-shadow: none;
}

/* Modal */


/*===================================
  Utilities
====================================*/

.xs-border-bottom {
  border-bottom: 1px solid rgb(222, 226, 230);
}

.font-weight-300 {
  font-weight: 300;
}

/* Borders ------------------------ */
@media (min-width: 768px) {
  .md-border-right {
    border-right: 1px solid rgb(222, 226, 230);
  }
  .md-border-bottom {
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  .md-no-border-bottom {
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .lg-border-right {
    border-right: 1px solid rgb(222, 226, 230);
  }
  .lg-no-border-right {
    border-right: none;
  }
  .lg-border-bottom {
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  .lg-no-border-bottom {
    border-bottom: none;
  }
}

/* Colors ------------------------ */
.bg-light-gray {
  background-color:#f7f7f7;
}

/* Grid */
.d-grid {
  display: grid;
}
.place-content-center {
  place-content: center;
}

/* Sizing ------------------------ */
@media (min-width: 768px) {
  .mw-md-500 {
    max-width: 500px;
  }
}
