#Loading {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: 300ms;
}

#Loading.hide {
  opacity: 0;
  visibility: hidden;
}

#Loading .loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

#Loading .loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #232323;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}